import React, {useEffect, useState} from 'react';
import api from "../../../api/post";
import "./comments.css"

const Comments = (props) => {

    let commentsContainer;

    const findReplies = (id) => {
        let replay = 0;
        for (let i = 0; i < props.data.length; i++) {
            for (let j = 0; j < props.data[i].comments.length; j++) {
                if (id === props.data[i].comments[j].parentCommentId) {
                    replay++
                }
            }
        }
        return replay;
    }

    if (props.data) {
        commentsContainer = props.data.map((item) => {
            return (
                <div key={item.id} className={"comments-container"}>
                    <h5>{item.name}</h5>
                    {item.comments.filter(item => item.parentCommentId === null).map((filtered) => {
                        return (
                            <div key={filtered.id} className={"comments-content rel"}
                                 onClick={() => props.activate(item.id, filtered)}>
                                <h5>{filtered.reviewer}</h5>
                                <h4>{filtered.commentText}</h4>
                                <span className={"replies"}>Replies: {findReplies(filtered.id)}</span>
                            </div>
                        )
                    })}
                </div>
            )
        })
    }

    return (
        <div className={"comments-wrapper h-100"}>
            <h4>Comments</h4>
            {commentsContainer}
        </div>
    );
};

export default Comments;