import React, {useEffect, useState} from 'react';
import api from "../../api/post";
import "./commentTextArea.css"

const CommentTextArea = (props) => {

    let commentsArea;
    let comments;
    let textArea;

    const [areaText, setAreaText] = useState("");
    const [disable, setDisable] = useState(true);
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 600) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [])

    const handlePropagation = (e) => {
        e.stopPropagation();
    }

    const handleText = (e) => {
        setAreaText(e.target.value);
        if (e.target.value !== "") {
            setDisable(false);
        } else {
            setDisable(true);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisable(true);
        let commentsOjc = {
            "adId": props.adId,
            "commentText": areaText,
            "coordinateX": props.data.cordX,
            "coordinateY": props.data.cordY,
            "id": "",
            "parentCommentId": props.parentId,
            "time": props.data.time,
        }

        api.post('/saveAdComment', commentsOjc,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tokenString")}`
                }
            })
            .then(function (response) {
                props.saveComment();
                props.clear();
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    if (props.data.comments.length > 0) {
        comments = props.data.comments.map((item, index) => {
            return (
                <div key={index} className={"actual-comment"}>
                    <h5>{localStorage.getItem("user")} Comment</h5>
                    <h4>{item}</h4>
                </div>
            )
        })
        textArea = <>
            <textarea rows="3" className={"w-100"} placeholder={"Reply"} onChange={(e) => handleText(e)}/>
            <div className={"w-100 textarea-action"}>
                <button className={"cancel-btn"} onClick={props.clear}>Cancel</button>
                <button className={disable ? "reply-btn disabled-btn" : "reply-btn"} type={"submit"}>Reply</button>
            </div>
        </>
    } else {
        textArea = <>
            <textarea rows="3" className={"w-100"} placeholder={"Write your comment here"}
                      onChange={(e) => handleText(e)}/>
            <div className={"w-100 textarea-action"}>
                <button className={"cancel-btn"} onClick={props.clear}>Cancel</button>
                <button className={disable ? "reply-btn disabled-btn" : "reply-btn"} type={"submit"}>Reply</button>
            </div>
        </>
    }

    if (props.data.active) {
        commentsArea = (
            <div className={"text-area"}>
                <form onSubmit={handleSubmit}>
                    {comments}
                    {textArea}
                </form>
            </div>
        )
    }

    return (
        <div onClick={(e) => handlePropagation(e)} className={"abs comment-area"}
             style={ mobile && props.data.active ? {bottom: 0, left: 0, width: "100%", height:"60%",position:"fixed"} : {top: props.data.cordY, left: props.data.cordX}}>
            {commentsArea}
        </div>
    );
};

export default CommentTextArea;