import React, {useEffect, useState, useRef} from 'react';
import "./points.css";
import CommentTextArea from "../../../../component/commentTextArea/commentTextArea";
import play from "../../../../assets/play-button.png";
import pause from "../../../../assets/pause.png";

const Points = (props) => {

    let commentsContainer;
    let playerContainer;
    let defaultCommentArea = {
        active: false,
        cordX: "",
        cordY: "",
        comments: [],
        time: 0,
    };

    const [parentId, setParentId] = useState("");
    const [commentsArea, setCommentsArea] = useState(defaultCommentArea);

    const clearFunction = () => {
        setCommentsArea(defaultCommentArea);
    }


    const findLocation = (e) => {
        let percentageArray = [];
        let element = document.querySelector(".points-container");
        let rect = element.getBoundingClientRect();
        let x = rect.left;
        let y = rect.top;
        let w = rect.width;
        let h = rect.height;
        let percentageX = ((e.pageX - x) / w) * 100;
        percentageArray.push(percentageX.toFixed(1));
        let percentageY = ((e.pageY - y) / h) * 100;
        percentageArray.push(percentageY.toFixed(1));
        return percentageArray;
    }

    const triggerCommentsArea = (content, e) => {
        if (e) {
            e.stopPropagation();
        }
        let allReplies = props.comments.filter(item => (content.id === item.parentCommentId || content.id === item.id)).map((filtered) => {
            return filtered.commentText;
        });
        setParentId(content.id);
        setCommentsArea((prevState) => {
            return {
                ...prevState,
                active: true,
                cordX: content.coordinateX,
                cordY: content.coordinateY,
                comments: allReplies
            }
        });
    }

    const createCommentsArea = (e) => {
        e.stopPropagation();
        const cords = findLocation(e);
        const x = cords[0];
        const y = cords[1];
        setParentId("");
        setCommentsArea((prevState) => {
            return {
                ...prevState,
                active: true,
                cordX: x + "%",
                cordY: y + "%",
                comments: [],
                time: props.videoCurrentTime
            }
        });
    }


    useEffect(() => {
        if (props.activeComment && Object.keys(props.activeComment).length !== 0) {
            triggerCommentsArea(props.activeComment);
        }
    }, [props.activeComment])


    if (props.media.includes(".mp4")) {
        playerContainer = <div className={"player-control abs w-100 flex-center-cl"}>

            {props.videoStatus ? <img src={pause} alt="" onClick={props.videoAction}/> : <img src={play} alt="" onClick={props.videoAction}/>}
            <span className={"videoDuration"}></span>
        </div>
    }

    if (props.comments.length > 0) {
        commentsContainer = props.comments.filter(item => item.parentCommentId === null).map((filtered, index) => {
            return (
                <div key={filtered.id} onClick={(e) => triggerCommentsArea(filtered, e)}
                     className={"comment-pin abs flex-center-rw"}
                     style={{top: filtered.coordinateY, left: filtered.coordinateX}}>
                    <span>{index + 1}</span>
                </div>
            )
        })
    }

    return (
        <>
            <div className={"points-container abs w-100 h-100"} onClick={(e) => createCommentsArea(e)}>
                {commentsContainer}
                <CommentTextArea data={commentsArea} adId={props.adId} parentId={parentId} clear={clearFunction}
                                 saveComment={props.saveComment}/>
            </div>
            {playerContainer}
        </>
    );
};

export default Points;