import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import Topbar from "./container/Topbar/Topbar";
import Review from "./container/Review/Review";
import Login from "./container/Login/Login"

function App() {

    const [login, setLogin] = useState(false);

    const enterUser = () => {
        setLogin(true)
    }

    const LogOutUser = () => {
        localStorage.removeItem("tokenString");
        setLogin(false)
    }

    useEffect(() => {
        if(localStorage.getItem("tokenString")) {
            enterUser();
        }
    },[])

    return (
        <Router>
            {login ?
                <div className="App">
                    <Topbar
                        logout={LogOutUser}
                    />
                    <Review/>
                </div> :
                <Login enter={enterUser}/>
            }

        </Router>
    );
}

export default App;
