import React, {useEffect, useState} from 'react';
import Ads from "./Ads/Ads";
import Editor from "./Editor/Editor";
import Comments from "./Comments/Comments";
import "./review.css";
import api from "../../api/post"


const Review = () => {
    const [content, setContent] = useState();
    const [activeAd, setActiveAd] = useState();
    const [activeComment, setActiveComment] = useState({});
    const [editorActive, setEditorActive] = useState();
    const [triggerReload, setTriggerReload] = useState(0);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const personaIdParams = urlParams.get('personaId');
        const statusParams = urlParams.get('statuses');
        api.get('/getAdsAndCommentsOfPersonaByStatus', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tokenString")}`
            },
            params: {
                personaId: personaIdParams,
                statuses: statusParams,
            }
        })
            .then(function (response) {
                setContent(response.data);
                let editorContent;
                if (activeAd) {
                    editorContent = response.data.filter(item => item.id === activeAd);
                } else {
                    setActiveAd(response.data[0].id);
                    editorContent = response.data.slice(0, 1);
                }
                setEditorActive(editorContent);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [triggerReload])

    const changeActiveAd = (active) => {
        setActiveAd(active);
        let editorActiveContent = content.filter(item => item.id === active);
        setEditorActive(editorActiveContent);
    }

    const saveNewAd = () => {
        setTriggerReload(triggerReload+1);
    }
    //
    // const updateTrigger = (update) => {
    //     changeActiveAd(update);
    // }
    //
    const activateFromComment= (active,activeComment) => {
        changeActiveAd(active);
        setActiveComment(activeComment)
    }

    return (
        <div className={"review-wrapper w-100"}>
            <Ads
                data={content}
                active={activeAd}
                changingActive={activateFromComment}
            />
            <Editor
                data={editorActive}
                saveComment={saveNewAd}
                activeComment={activeComment}
            />
            <Comments
                data={content}
                activate={activateFromComment}
            />
        </div>
    );
};

export default Review;