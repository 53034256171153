import React from 'react';
import "./topbar.css"
import Logo from "../../assets/Logo.png"
import msgIcon from "../../assets/message-icon.png"

const Topbar = (props) => {
    return (
        <div className={"topbar-wrapper w-100"}>
            <img src={Logo} alt="Logo of STEPS"/>
            <div className={"share-wrapper"}>
                <img src={msgIcon} alt="Logo of STEPS"/>
                <button onClick={props.logout}>Logout</button>
            </div>
        </div>
    );
};

export default Topbar;