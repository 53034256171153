import React from 'react';
import "./ads.css"

const Ads = (props) => {

    let listImages;

    if (props.data) {
        listImages = props.data.map((item) => {
                return (
                    <div key={item.id}
                         className={props.active === item.id ? "active-ad rel img-container pointer-none" : "rel img-container"}
                         onClick={() => props.changingActive(item.id,{})}>
                        {item.finalMediaUrl.includes(".mp4") ? (<>
                                <video controls className={"canLendComment"}>
                                    <source src={item.finalMediaUrl} type="video/mp4"/>
                                </video>
                            </>) :
                            <img src={item.finalMediaUrl} alt="" className={"canLendComment"}/> }
                    </div>
                )
            }
        );
    }

    return (
        <div className={"ads-wrapper"}>
            {listImages}
        </div>
    );
};

export default Ads;