import React, {useEffect, useState} from 'react';
import "./editor.css";
import Points from "../Editor/Points/Points";
import api from "../../../api/post";


const Editor = (props) => {

    let editorContainer;
    let video;
    const [isPlaying, setIsPlaying] = useState(false);
    const [videoLength, setVideoLength] = useState();
    const [videoCurrentTime, setVideoCurrentTime] = useState(0);


    const videoFirstDetails = (e) => {
        video=e.target;
        let fullDuration = video.duration * 1000;
        let milisecDuration = Math.round(fullDuration);
        setVideoLength(milisecDuration);
        video.volume = 0;
    }

    const playPauseVideo = () => {
        let videoItem = document.querySelector(".editor video");
        setIsPlaying(!isPlaying);
        if (isPlaying) {
            videoItem.pause();
            let currentTime = videoItem.currentTime * 1000;
            let milisecCurrentTime = Math.round(currentTime);
            setVideoCurrentTime(milisecCurrentTime);
        } else {
            videoItem.play();
        }

    }

    if (isPlaying === false) {
        let videoCurrentTime = document.querySelector(".editor video");
    }

    if (props.data) {
        let sortedArray = props.data[0].comments.sort((a,b) => (new Date(a.createDate) > new Date(b.createDate)) ? 1 : ((new Date(b.createDate) > new Date(a.createDate)) ? -1 : 0));
        let editorData = props.data;
        editorData[0].comments = sortedArray;
        editorContainer = editorData.map((item) => {
            return (
                <div key={item.id} className={"editor rel"}>

                    
                    {item.finalMediaUrl.includes(".mp4") ? (<>
                            <video className={"canLendComment"} onLoadedData={(e)=>videoFirstDetails(e)}>
                                <source src={item.finalMediaUrl} type="video/mp4"/>
                            </video>
                        </>) :
                        <img src={item.finalMediaUrl} alt="" className={"canLendComment"}/> }
                    <Points
                        saveComment={props.saveComment}
                        comments={item.comments}
                        adId={item.id}
                        activeComment={props.activeComment}
                        media={item.finalMediaUrl}
                        videoStatus={isPlaying}
                        videoAction={playPauseVideo}
                        videoLength={videoLength}
                        videoCurrentTime={videoCurrentTime}
                    />
                </div>
            )
        });
    }


    return (
        <div className={"editor-wrapper h-100 rel"}>
            <div className={"editor-container rel"}>
                {editorContainer}
            </div>
        </div>
    );
};

export default Editor;