import React, {useState} from 'react';
import './Login.css';
import axios from "axios";
import logo from "../../assets/Logo.svg"

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://dev.steps.staircasedigital.com/loginFromWeb', {
            "password": password,
            "username": email
        })
            .then(function (response) {
                localStorage.setItem("tokenString",response.data.token.token);
                localStorage.setItem("user",response.data.user);
                localStorage.setItem("lastComments","");
                if (localStorage.getItem("tokenString")) {
                    props.enter();
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    return (
        <div className={"login-wrapper flex-center-cl"}>
            <img src={logo} alt="Steps Logo"/>
            <h1>Sign in to Ad Reviewer</h1>
            <h4>Enter the password we have sent to your email</h4>
            <form onSubmit={handleSubmit} className={"flex-center-cl"}>
                <input
                    type="text"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    placeholder={"Username"}
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    placeholder={"Password"}
                />
                <button type={"submit"} className={"flex-center-cl"}>Continue</button>
            </form>
        </div>
    );
};

export default Login;